import * as React from "react";
import Box from "@mui/material/Box";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";

import {
  Accordion,
  Avatar,
  Checkbox,
  Drawer,
  List,
  ListItem,
  ListItemText,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { useConversationsApi } from "../../../api/conversationsApi";
import { useEffect } from "react";
import { useState } from "react";
import AgentCard from "../../agents/AgentCard";
import { useContext } from "react";
import { ConversationContext } from "../providers/ConversationProvider";
import { Link } from "react-router-dom";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useSwipeable } from "react-swipeable";

export default function AgentDrawer(props) {
  const theme = useTheme();

  const swipeHandlers = useSwipeable({
    onSwipedRight: () => {
      console.log("swiped right");
      toggleDrawer(false);
    },
  });

  const { selectedConversation, conversationAgents, setConversationAgents } =
    useContext(ConversationContext);

  const {
    getAgents,
    addConversationAgent,
    removeConversationAgent,
    getOrchestrationConfiguration,
    updateOrchestrationConfiguration,
  } = useConversationsApi();

  const { sx, activeAgentsOpen, setActiveAgentsOpen, ...otherProps } = props;

  const [agents, setAgents] = useState([]);

  const [orchestrationConfiguration, setOrchestrationConfiguration] = useState({
    allow_user_goal_evaluation: false,
    allow_user_plan_evaluation: false,
    max_plan_execution_iterations: 1,
    allow_direct_answer: true,
  });

  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setActiveAgentsOpen(open);
  };

  useEffect(() => {
    getAgents().then((response) => {
      setAgents(response);
    });
  }, [conversationAgents]);

  useEffect(() => {
    getOrchestrationConfiguration().then((response) => {
      setOrchestrationConfiguration(response);
    });
  }, []);

  const handleOrchestrationConfigurationUpdate = async (
    updatedOrchestrationConfiguration
  ) => {
    await updateOrchestrationConfiguration(updatedOrchestrationConfiguration);
    setOrchestrationConfiguration(updatedOrchestrationConfiguration);
  };

  const handleAgentSelection = (agent) => {
    // Check to see if the conversationAgents list includes an agent with the same id
    // If the agent is already in the list, remove it
    // If the agent is not in the list, add it
    // The agent in the list is an object, not just an id
    const agentIndex = conversationAgents.findIndex(
      (conversationAgent) => conversationAgent.id === agent.id
    );

    if (agentIndex > -1) {
      // Remove the agent from the conversation using the API (if there's a conversation)
      if (selectedConversation && selectedConversation !== "new") {
        removeConversationAgent(selectedConversation, agent.id);
      }

      // Remove the agent from the conversation locally
      setConversationAgents((prevAgents) => {
        return prevAgents.filter((conversationAgent) => {
          return conversationAgent.id !== agent.id;
        });
      });
    } else {
      // Add the agent to the conversation using the API (if there's a conversation)
      if (selectedConversation && selectedConversation !== "new") {
        addConversationAgent(selectedConversation, agent.id);
      }

      // Add the agent to the conversation locally
      setConversationAgents((prevAgents) => {
        return [...prevAgents, agent];
      });
    }
  };

  const content = () => (
    <Box
      sx={{
        sx,
        maxWidth: "90dvw",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          p: 2,
          bgcolor: theme.palette.primary.main,
        }}
      >
        <Typography variant="h5" component="h1">
          Agents
          <Typography variant="body2" component="p">
            Agents are specialized AI bots that can be used to perform various
            tasks.
          </Typography>
        </Typography>
      </Box>
      <Typography
        variant="caption"
        paragraph
        sx={{ fontStyle: "italic", textAlign: "end", pr: 2 }}
      >
        You can create or edit agents in the <Link to="/agents">Agents</Link>{" "}
        section.
      </Typography>
      <Accordion sx={{ pb: 2 }} defaultExpanded>
        <AccordionSummary
          expandIcon={
            <ExpandMoreIcon
              sx={{ color: theme.palette.secondary.contrastText }}
            />
          }
          aria-controls="active-agents-content"
          id="active-agents-header"
          sx={{ bgcolor: theme.palette.secondary.main }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              color: theme.palette.secondary.contrastText,
            }}
          >
            <Typography variant="h6">Active Agents</Typography>

            <Typography variant="caption">
              Select the agents that you would like to participate in this
              conversation.
            </Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <Box>
            <Typography
              variant="caption"
              sx={{
                fontStyle: "italic",
                color: theme.palette.warning.light,
                my: 1,
              }}
            >
              Selected agents apply only to the current conversation.
            </Typography>
            <List sx={{ maxWidth: "500px" }}>
              <ListItem key={"agent-zippy"}>
                <Checkbox key="agent-checkbox-zippy" checked={true} disabled />
                <Avatar
                  key="agent-avatar-zippy"
                  alt="Zippy"
                  src="/zippy.png"
                  sx={{ mr: 1, bgcolor: theme.palette.secondary.light }}
                >
                  Z
                </Avatar>
                <ListItemText
                  key="agent-text-zippy"
                  primary="Zippy"
                  secondary="Zippy is always active in conversations, coordinating other agents or providing answers itself."
                />
              </ListItem>
              {agents.map((agent, index) => (
                <ListItem key={"agent-" + index}>
                  <Checkbox
                    key={"agent-checkbox-" + index}
                    value={agent.id}
                    label={agent.name}
                    checked={conversationAgents.some(
                      (conversationAgent) => conversationAgent.id === agent.id
                    )}
                    onChange={() => handleAgentSelection(agent)}
                  />
                  <Avatar
                    key={"agent-avatar-" + index}
                    alt={agent.name}
                    src={agent.image_url}
                    sx={{ mr: 1, bgcolor: theme.palette.secondary.light }}
                  >
                    {agent.name[0]}
                  </Avatar>
                  <ListItemText
                    key={"agent-text-" + index}
                    primary={agent.name}
                    secondary={agent.description}
                  />
                </ListItem>
              ))}
            </List>
          </Box>
        </AccordionDetails>
      </Accordion>

      {/* <Accordion>
        <AccordionSummary
          expandIcon={
            <ExpandMoreIcon
              sx={{ color: theme.palette.secondary.contrastText }}
            />
          }
          aria-controls="agent-orchestration-content"
          id="agent-orchestration-header"
          sx={{ bgcolor: theme.palette.secondary.main }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              color: theme.palette.secondary.contrastText,
            }}
          >
            <Typography variant="h6">Orchestration</Typography>

            <Typography variant="caption">
              How Zippy coordinates with you and other agents to complete tasks.
            </Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <Box sx={{ maxWidth: "500px" }}>
            <Typography variant="body2">
              These rules can help you manage the conversation and ensure that
              the agents are working together effectively.
            </Typography>
            <Typography
              variant="caption"
              sx={{
                fontStyle: "italic",
                color: theme.palette.warning.light,
                my: 1,
              }}
            >
              These settings apply across all conversations.
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                p: 1,
              }}
            >
              <Checkbox
                checked={orchestrationConfiguration.allow_direct_answer}
                onChange={async (event) => {
                  // Update the orchestration configuration
                  let updatedOrchestrationConfiguration = {
                    ...orchestrationConfiguration,
                    allow_direct_answer: event.target.checked,
                  };
                  await handleOrchestrationConfigurationUpdate(
                    updatedOrchestrationConfiguration
                  );
                }}
              />
              <Typography variant="body2">
                Allow Zippy to answer the query directly, if possible, without
                using other agents
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                p: 1,
              }}
            >
              <Checkbox
                checked={orchestrationConfiguration.allow_user_goal_evaluation}
                onChange={async (event) => {
                  // Update the orchestration configuration
                  let updatedOrchestrationConfiguration = {
                    ...orchestrationConfiguration,
                    allow_user_goal_evaluation: event.target.checked,
                  };
                  await handleOrchestrationConfigurationUpdate(
                    updatedOrchestrationConfiguration
                  );
                }}
              />
              <Typography variant="body2">
                Evaluate and edit Zippy's proposed goals before the final plan
                is created
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                p: 1,
              }}
            >
              <Checkbox
                checked={orchestrationConfiguration.allow_user_plan_evaluation}
                onChange={async (event) => {
                  // Update the orchestration configuration
                  let updatedOrchestrationConfiguration = {
                    ...orchestrationConfiguration,
                    allow_user_plan_evaluation: event.target.checked,
                  };

                  await handleOrchestrationConfigurationUpdate(
                    updatedOrchestrationConfiguration
                  );
                }}
              />
              <Typography variant="body2">
                Evaluate and edit the plan before Zippy executes it, including
                which agents are assigned which tasks
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                p: 1,
              }}
            >
              <TextField
                type="number"
                value={orchestrationConfiguration.max_plan_execution_iterations}
                sx={{ width: "100px" }}
                onChange={async (event) => {
                  // Update the orchestration configuration
                  let updatedOrchestrationConfiguration = {
                    ...orchestrationConfiguration,
                    max_plan_execution_iterations: parseInt(event.target.value),
                  };

                  await handleOrchestrationConfigurationUpdate(
                    updatedOrchestrationConfiguration
                  );
                }}
              />
              <Typography variant="body2" sx={{ ml: 2 }}>
                Maximum plan execution iterations
              </Typography>
            </Box>
          </Box>
        </AccordionDetails>
      </Accordion> */}
    </Box>
  );

  return (
    <div {...swipeHandlers}>
      <Drawer
        id="active-agents-drawer"
        key={"active-agents-drawer"}
        anchor={"right"}
        open={activeAgentsOpen}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
      >
        {content()}
      </Drawer>
    </div>
  );
}
