import React, { useState, useEffect, useContext, useCallback } from "react";
import { set, throttle } from "lodash";
import { useConversationsApi } from "../../api/conversationsApi";
import { parse, Allow } from "partial-json";
import _ from "lodash";

import {
  TextField,
  InputAdornment,
  Button,
  Container,
  List,
  Box,
  CircularProgress,
  Typography,
  Tooltip,
  Popover,
  ListItemButton,
  Backdrop,
} from "@mui/material";

import AttachFileTwoToneIcon from "@mui/icons-material/AttachFileTwoTone";

import { BsFillSendFill } from "react-icons/bs";
import { useDropzone } from "react-dropzone";

import { ConversationContext } from "./providers/ConversationProvider.js";
import { useTheme } from "@mui/material/styles";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

import { useDocumentsApi } from "../../api/documentsApi";
import FileDisplay from "./components/FileDisplay.jsx";
import { MessageFile } from "./classes/MessageFile.js";
import FileSelectionDialog from "./components/FileSelectionDialog.jsx";
import MessageList from "./components/MessageList.jsx";

import { useWebsockets } from "./hooks/useWebsockets";
import { useMemo } from "react";
import DrawerComponent from "./components/DrawerComponent";
import AgentDrawer from "./components/AgentsDrawer.jsx";
import ActiveAgents from "./components/ActiveAgents.jsx";

const drawerWidth = 300;

// Conversations component takes "mode" as a prop, which is the theme mode
function Conversations(props) {
  const theme = useTheme();
  const { getConversationMessages, query } = useConversationsApi();
  const {
    fetchUploadUrl,
    uploadDocument,
    getAllUploadedFilesForConversationDisplay,
    getDownloadUrl,
  } = useDocumentsApi();
  const textareaRef = React.useRef(null);

  const [activeAgentsOpen, setActiveAgentsOpen] = React.useState(false);

  const [isSendingMessage, setIsSendingMessage] = useState(false);
  const [isThinking, setIsThinking] = useState(false);
  const [isStreaming, setIsStreaming] = useState(false);

  const {
    selectedConversation,
    selectedConversationRef,
    setSelectedConversation,
    refreshMessagesTrigger,
    setStatus,
    setStatusOpen,
    setMessageHistory,
    currentMessage,
    setCurrentMessage,
    conversationAgents,
    isLoading,
    waitingForConversation,
    setWaitingForConversation,
    changeUrl,
    requestConversationListRefresh,
    setGoals,
  } = useContext(ConversationContext);

  const { webSocketControl } = useWebsockets(
    handleMessageIdData,
    handleStatusData
  );

  const useThrottledCallback = (callback, delay) => {
    const throttledCallback = useMemo(
      () => throttle(callback, delay),
      [callback, delay]
    );
    return throttledCallback;
  };

  // useEffect to handle when refreshMessagesTrigger changes
  useEffect(() => {
    if (selectedConversation && selectedConversation !== "new") {
      handleTextareaFocus();
    }
  }, [refreshMessagesTrigger]);

  const [accumulatedBytes, setAccumulatedBytes] = useState([]);
  const [accumulatedData, setAccumulatedData] = useState({
    thoughts: "",
    status: {},
    answer: "",
    disclaimer: "",
  });

  useEffect(() => {
    if (accumulatedBytes && accumulatedBytes.length > 0) {
      let accumulatedText = new TextDecoder("utf-8").decode(accumulatedBytes);

      let data = parse(accumulatedText, Allow.ALL);

      if (data["conversation_wrapper"]) {
        if (
          data["conversation_wrapper"]["conversation_id"] &&
          (!selectedConversation || selectedConversation === "new")
        ) {
          const conversationId =
            data["conversation_wrapper"]["conversation_id"];
          onConversationIdReceived(conversationId);
        }

        if (data["conversation_wrapper"]["answer_stage"]) {
          handleAnswerStage(data);
        }

        if (data["conversation_wrapper"]["goal_setting_stage"]) {
          handleGoalSettingStage(data);
        }

        if (data["conversation_wrapper"]["error"]) {
          onErrorReceived(data["conversation_wrapper"]["error"]);
        }
      }
    }
  }, [accumulatedBytes]);

  function handleGoalSettingStage(data) {
    setGoals(data["conversation_wrapper"]["goal_setting_stage"]["goals"]);
  }

  function handleAnswerStage(data) {
    if (data["conversation_wrapper"]["answer_stage"]["answer"]) {
      if (
        accumulatedData.answer !==
        data["conversation_wrapper"]["answer_stage"]["answer"]
      ) {
        // Update the answer in the accumulatedData
        setAccumulatedData({
          ...accumulatedData,
          answer: data["conversation_wrapper"]["answer_stage"]["answer"],
        });

        onAnswerReceived(
          data["conversation_wrapper"]["answer_stage"]["answer"]
        );
      }
    }

    if (data["conversation_wrapper"]["answer_stage"]["disclaimer"]) {
      if (
        accumulatedData.disclaimer !==
        data["conversation_wrapper"]["answer_stage"]["disclaimer"]
      ) {
        // Update the disclaimer in the accumulatedData
        setAccumulatedData({
          ...accumulatedData,
          disclaimer:
            data["conversation_wrapper"]["answer_stage"]["disclaimer"],
        });

        onDisclaimerReceived(
          data["conversation_wrapper"]["answer_stage"]["disclaimer"]
        );
      }
    }
  }

  const handleTextareaFocus = () => {
    const current = textareaRef.current;
    current?.focus();
  };

  useEffect(() => {
    if (waitingForConversation) {
      return;
    }

    handleTextareaFocus();
  }, [selectedConversation]);

  function handleStatusData(data) {
    // This is a STATUS update from the websocket
    // Update the status and description
    // Only update if the conversation_id matches the selected conversation
    if (data.conversation_id === selectedConversationRef.current) {
      // use setStatus to append the new status data to the existing status data
      SetStatusData(data);
    } else {
      console.warn(
        "Ignoring status data because conversation_id does not match selected conversation" +
          data.conversation_id +
          " !== " +
          selectedConversationRef.current
      );
    }
  }

  function handleMessageIdData(data) {
    // New: In the currentMessage, update the message ID with the real ID
    // Then move the message to the messageHistory, also loop through all
    // of the messages in the history (should be the latest one) in reverse
    // order to find the message with the temporary ID and update it with the real ID
    // Only update if the conversation_id matches the selected conversation
    if (data.conversation_id === selectedConversationRef.current) {
      setCurrentMessage((prevMessage) => {
        if (prevMessage.temporary_id === data.temporary_id) {
          return {
            ...prevMessage,
            id: data.message_id,
          };
        } else {
          return prevMessage;
        }
      });

      setMessageHistory((prevMessages) => {
        const newMessages = [...prevMessages];
        for (let i = newMessages.length - 1; i >= 0; i--) {
          const message = newMessages[i];
          if (message.temporary_id === data.temporary_id) {
            // Update the message with the real ID
            newMessages[i].id = data.message_id;
            return newMessages;
          }
        }
        return newMessages;
      });
    }
  }

  const SetStatusData = (data) => {
    // incoming data looks like this:
    // { conversation_id: "09029f47-27c8-48e4-b3a2-a7207aee8448", operation_id: "09025f26-26c8-24e4-a3a1-a5741aee8448", agent_name: "Zippy", status: "in_progress", description: "Processing query..." }

    if (data === undefined) {
      console.warn("Ignoring undefined status data");
      return;
    }

    // Setting the status is additive, so we need to append the new status data to the existing status data
    // The status data looks like this: { Zippy: [ { status: "in_progress", description: "Processing query..." } ] }
    // For each new status we get in, we want to append it to the existing agent's status data
    // Like so: { Zippy: [ { status: "in_progress", description: "Processing query..." }, { status: "finished", description: "Query complete" } ] }

    setStatus((prevStatus) => {
      // If the agent doesn't exist in the status data, add it
      if (!prevStatus[data.agent_name]) {
        set(prevStatus, data.agent_name, []);
      }

      // Check to see if the operation ID already exists in the status data
      const operationIndex = prevStatus[data.agent_name].findIndex(
        (statusData) => statusData.operationId === data.operation_id
      );

      if (operationIndex !== -1) {
        // If this operation ID already exists, update the status data
        prevStatus[data.agent_name][operationIndex] = {
          status: data.status,
          description: data.description,
          operationId: data.operation_id,
          operationName: data.operation_name,
        };
      } else {
        // If this operation ID does not exist, add the new status data to the existing status data
        prevStatus[data.agent_name].push({
          status: data.status,
          description: data.description,
          operationId: data.operation_id,
          operationName: data.operation_name,
        });
      }

      return { ...prevStatus };
    });
  };

  function onConversationIdReceived(conversationId) {
    setSelectedConversation(conversationId);
  }

  const streamStarted = () => {
    setIsStreaming(true);
    setStatusOpen(true);
  };

  const onAnswerReceived = (newAnswer) => {
    setIsThinking(false);
    setCurrentMessage((prevMessage) => {
      return {
        ...prevMessage,
        content: newAnswer,
      };
    });
  };

  const onErrorReceived = (chunk) => {
    setIsThinking(false);
    // Update the last message in the conversation with the new chunk by appending it
    setCurrentMessage((prevMessage) => {
      return {
        ...prevMessage,
        content: prevMessage.content + chunk,
        role: "error",
      };
    });
  };

  function streamCompleted(conversationId) {
    setIsSendingMessage(false);
    setIsStreaming(false);
    setIsThinking(false);
    setWaitingForConversation(false);
    setStatusOpen(false);

    // Change the URL to the conversation ID

    if (conversationId !== "new") {
      changeUrl(conversationId);
    }

    // Refresh the conversation list after like 2 seconds
    setTimeout(() => {
      requestConversationListRefresh();
    }, 2000);
  }

  function onDisclaimerReceived(chunk) {
    // Write the disclaimer to the last message in the conversation under the "disclaimer" key
    setCurrentMessage((prevMessage) => {
      return {
        ...prevMessage,
        disclaimer: chunk,
      };
    });
  }

  const onSubmit = async () => {
    const message = document.getElementById("message-textfield").value;

    await onSubmitMessage(message);
  };

  const onSubmitMessage = async (message) => {
    // Don't do anything if the field is empty
    if (!message) {
      return;
    }

    // Clear the status
    setStatus({});

    if (!selectedConversation || selectedConversation === "new") {
      setWaitingForConversation(true);
      // Clear messages for the new conversation
      setMessageHistory([]);
    }

    setIsSendingMessage(true);

    document.getElementById("message-textfield").value = "";

    let associatedFileDetails = {};
    let messageFiles = {};
    // Upload the files that haven't been uploaded (first filter the files that haven't been uploaded, then upload them)
    const filesToUpload = Object.values(droppedFiles).filter(
      (file) => !file.uploaded
    );

    // For all of the files that have already been uploaded, associate them with the message
    for (const file of Object.values(droppedFiles).filter(
      (file) => file.uploaded
    )) {
      associatedFileDetails[file.file_name] = file.key;
    }

    // Add all of the dropped files to the messageFiles object
    for (const file of Object.values(droppedFiles)) {
      messageFiles[file.file_name] = file;
    }

    const uploadPromises = Object.values(filesToUpload).map(async (file) => {
      // If the file is already uploaded, skip the upload, but add it to the message
      // Get the upload URL
      const details = await fetchUploadUrl(file);
      associatedFileDetails[details.file_name] = details.key;

      // Also add the uploaded file key to the messageFiles (so the user can delete it before refreshing the page)
      messageFiles[details.file_name].key = details.key;

      // Upload the file
      return uploadDocument(
        file.fileObject,
        details,
        updateDroppedFileProgress,
        uploadComplete
      );
    });

    // Clear the dropped files display (should now be displayed in the message files)
    setDroppedFiles({});

    // Create some temporary IDs (strings) for the user and AI messages
    const temporaryUserMessageId = "user-" + Date.now();
    const temporaryAiMessageId = "ai-" + Date.now();

    // Move the previous currentMessage to the messageHistory (if there is one)
    if (currentMessage) {
      setMessageHistory((prevMessages) => [
        ...prevMessages,
        {
          temporary_id: currentMessage.temporary_id,
          id: currentMessage.id,
          role: currentMessage.role,
          content: currentMessage.content,
          associated_files: currentMessage.associated_files,
          reasoningSteps: currentMessage.reasoningSteps,
          thought: currentMessage.thought,
          disclaimer: currentMessage.disclaimer,
        },
      ]);
    }

    // Add the user's message to the conversation
    setMessageHistory((prevMessages) => [
      ...prevMessages,
      {
        temporary_id: temporaryUserMessageId,
        id: null,
        role: "user",
        content: message,
        associated_files: messageFiles,
      },
    ]);

    // Wait for all uploads to complete
    await Promise.all(uploadPromises);

    // Create the initial AI message
    setCurrentMessage({
      temporary_id: temporaryAiMessageId,
      id: null,
      role: "ai",
      content: "",
    });

    query(
      selectedConversation,
      message,
      conversationAgents,
      temporaryUserMessageId,
      temporaryAiMessageId,
      associatedFileDetails,
      setAccumulatedBytes,
      streamCompleted,
      streamStarted
    );
  };

  const [isDragActive, setIsDragActive] = useState(false);
  const [droppedFiles, setDroppedFiles] = useState({});

  function uploadComplete(fileName) {
    removeDroppedFile(fileName);
  }

  async function associateExistingFileWithOutgoingMessage(messageFile) {
    // Get the download URL for the file
    const downloadUrl = await getDownloadUrl(messageFile.key);

    messageFile.url = downloadUrl;

    setDroppedFiles((prevFiles) => {
      return {
        ...prevFiles,
        [messageFile.file_name]: messageFile,
      };
    });
  }

  const onPaste = (event) => {
    const items = event.clipboardData.items;
    const files = [];
    for (let i = 0; i < items.length; i++) {
      if (items[i].kind === "file") {
        const file = items[i].getAsFile();
        if (file) {
          files.push(file);
        }
      }
    }
    if (files.length > 0) {
      // Call addDroppedFile for each file
      files.forEach((file) => {
        addDroppedFile(file);
      });
    }
  };

  async function addDroppedFile(file) {
    let imgSrc = null;
    if (file.type.startsWith("image/")) {
      imgSrc = await new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(file);
      });
    }

    let fileObject = new MessageFile(
      file.name,
      file.type,
      imgSrc,
      null,
      null,
      file
    );

    setDroppedFiles((prevFiles) => {
      return {
        ...prevFiles,
        [fileObject.file_name]: fileObject,
      };
    });
  }

  function removeDroppedFile(file) {
    setDroppedFiles((prevFiles) => {
      const newFiles = { ...prevFiles };
      delete newFiles[file.file_name];
      return newFiles;
    });
  }

  function updateDroppedFileProgress(fileName, rawProgress) {
    // Make the progress a whole number
    let progress = Math.round(rawProgress);

    if (progress === null || progress === undefined) {
      progress = 100;
    }

    if (progress >= 100) {
      uploadComplete(fileName);
    }

    setMessageHistory((prevMessages) => {
      const newMessages = [...prevMessages];
      const lastMessageIndex = newMessages.length - 1;
      const lastMessage = { ...newMessages[lastMessageIndex] };
      const newAssociatedFiles = { ...lastMessage.associated_files };

      if (newAssociatedFiles && newAssociatedFiles[fileName]) {
        newAssociatedFiles[fileName].progress = progress;
      }

      lastMessage.associated_files = newAssociatedFiles;
      newMessages[lastMessageIndex] = lastMessage;

      return newMessages;
    });
  }

  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop: (acceptedFiles) => {
      acceptedFiles.forEach((file) => {
        addDroppedFile(file);
      });

      setIsDragActive(false);
    },
    onDragEnter: () => setIsDragActive(true),
    onDragOver: () => setIsDragActive(true),
    onDragLeave: () => setIsDragActive(false),
  });

  const [attachmentMenuAnchorEl, setAttachmentMenuAnchorEl] = useState(null);
  const attachmentMenuPopoverOpen = Boolean(attachmentMenuAnchorEl);

  const handleAttachmentMenuClick = (event) => {
    event.stopPropagation();
    setAttachmentMenuAnchorEl(event.currentTarget);
  };

  const handleAttachmentMenuPopoverClose = (event) => {
    event.stopPropagation();
    setAttachmentMenuAnchorEl(null);
  };

  const [fileSelectionOpen, setFileSelectionOpen] = useState(false);
  const [availableFiles, setAvailableFiles] = useState([]);

  const handleSelectFileFromExisting = async () => {
    // Get the files for this user
    const availableFiles = await getAllUploadedFilesForConversationDisplay();

    // turn the files into a list of MessageFile objects
    const availableFilesList = availableFiles.map((file) => {
      return new MessageFile(
        file.file_name,
        file.file_type,
        null,
        file.ui_key,
        file.file_description,
        null,
        0,
        true
      );
    });

    setAvailableFiles(availableFilesList);

    setFileSelectionOpen(true);
  };

  const onFileSelectionClosed = async (selectedFiles) => {
    setFileSelectionOpen(false);

    if (selectedFiles && selectedFiles.length > 0) {
      // Add the selected files to the dropped files
      for (const file of selectedFiles) {
        await associateExistingFileWithOutgoingMessage(file);
      }
    }
  };

  return (
    <Container
      id="main-conversation-container"
      disableGutters
      maxWidth={false}
      sx={{
        // overflow: "auto",
        display: "flex",
        flexDirection: "column",
      }}
      {...getRootProps({ onClick: (event) => event.stopPropagation() })} // Dropzone props
      // {...rightSwipeHandlers} // Swipeable props
    >
      <FileSelectionDialog
        onClose={onFileSelectionClosed}
        open={fileSelectionOpen}
        files={availableFiles}
      />
      <Box
        id="file-upload-box"
        sx={{
          position: "fixed",
          width: "100%",
          height: "100%",
          display: isDragActive ? "flex" : "none",
          bgcolor: "rgba(233, 233, 233, 0.75)",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
      />
      <input
        type="hidden"
        name="fileUploadInput"
        {...getInputProps({ onClick: (event) => event.stopPropagation() })}
      />
      <Box sx={{ display: "flex", flexDirection: "row" }}>
        <DrawerComponent drawerWidth={drawerWidth} />
        <AgentDrawer
          activeAgentsOpen={activeAgentsOpen}
          setActiveAgentsOpen={setActiveAgentsOpen}
        />

        <Box
          id="message-box"
          sx={{
            display: "flex",
            flexDirection: "column",
            width: { xs: "100%", sm: "calc(100vw - " + drawerWidth + "px)" },
          }}
        >
          {/* <Box
            sx={{
              maxHeight: "30px",
              m: 1,
              mr: "25px",
              zIndex: 100,
              position: "fixed",
            }}
          >
            Placeholder for model control
          </Box> */}
          <ActiveAgents
            sx={{
              maxHeight: "30px",
              m: 1,
              mr: "25px",
              zIndex: 100,
              position: "fixed",
              right: 0,
            }}
            onClick={() => setActiveAgentsOpen(true)}
          />
          <Box
            id="message-container"
            direction={"column"}
            sx={{
              display: "flex",
              flexGrow: 1,
              flexDirection: "column",
              height: "100dvh",
              maxHeight: { xs: "calc(100dvh - 30px)", sm: "100dvh" },
            }}
          >
            <Backdrop
              id="loading-backdrop"
              open={isLoading}
              sx={{
                maxWidth: {
                  xs: "100%",
                  sm: "calc(100vw - " + drawerWidth + "px)",
                },
                left: { sm: drawerWidth },
                top: { sm: "64px", xs: "56px" },
                color: "#fff",
                zIndex: (theme) => theme.zIndex.drawer,
              }}
            >
              <CircularProgress sx={{ color: theme.palette.primary.main }} />
            </Backdrop>

            <MessageList
              conversationId={selectedConversation}
              isThinking={isThinking}
              isSendingMessage={isSendingMessage}
              theme={theme}
              drawerWidth={drawerWidth}
              onSubmitMessage={onSubmitMessage}
            />

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                maxWidth: {
                  xs: "100%",
                  sm: "calc(100vw - " + drawerWidth + "px)",
                },
                pb: 10,
                pl: 5,
                pr: 5,
              }}
              id="user-query-box"
            >
              <FileDisplay
                removeFile={removeDroppedFile}
                files={droppedFiles}
                isMessage={false}
                canRemove={true}
              />

              <form noValidate autoComplete="off">
                <TextField
                  id={"message-textfield"}
                  size="small"
                  label={
                    !isStreaming
                      ? "Enter your message here..."
                      : "Please wait for Zippy to finish..."
                  }
                  inputRef={textareaRef}
                  multiline
                  maxRows={15}
                  sx={{
                    color: !isStreaming
                      ? theme.palette.secondary.main
                      : theme.palette.primary.dark,
                  }}
                  fullWidth={true}
                  onPaste={onPaste}
                  onKeyDown={(e) => {
                    if (e.key === "Enter" && !e.shiftKey) {
                      e.preventDefault();
                      if (!isStreaming) {
                        onSubmit();
                      }
                    }
                  }}
                  InputProps={{
                    sx: { alignItems: "end" },
                    startAdornment: (
                      <InputAdornment
                        position="start"
                        sx={{
                          alignItems: "flex-start",
                          alignSelf: "flex-start",
                          padding: 0,
                          margin: 0,
                        }}
                      >
                        <Tooltip title="Attach a file (or files) to your message">
                          <AttachFileTwoToneIcon
                            sx={{
                              marginRight: "4px",
                              height: "30px",
                              cursor: "pointer",
                              color: theme.palette.primary.main,
                            }}
                            onClick={handleAttachmentMenuClick}
                          />
                        </Tooltip>
                        <Popover
                          id="attachment-popover"
                          open={attachmentMenuPopoverOpen}
                          anchorEl={attachmentMenuAnchorEl}
                          onClose={handleAttachmentMenuPopoverClose}
                          anchorOrigin={{
                            vertical: "top",
                            horizontal: "left",
                          }}
                          transformOrigin={{
                            vertical: "bottom",
                            horizontal: "center",
                          }}
                        >
                          <List>
                            <ListItemButton
                              sx={{ display: "flex", flexDirection: "row" }}
                              onClick={(e) => {
                                handleAttachmentMenuPopoverClose(e);
                                open();
                              }}
                            >
                              <UploadFileIcon sx={{ marginRight: "8px" }} />
                              <Typography variant="caption">
                                Upload files from your device
                              </Typography>
                            </ListItemButton>
                            <ListItemButton
                              sx={{ justifyItems: "center" }}
                              onClick={(e) => {
                                handleAttachmentMenuPopoverClose(e);
                                handleSelectFileFromExisting();
                              }}
                            >
                              <CloudUploadIcon sx={{ marginRight: "8px" }} />
                              <Typography variant="caption">
                                Attach files you've already uploaded
                              </Typography>
                            </ListItemButton>
                          </List>
                        </Popover>
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        sx={{
                          alignItems: "flex-end",
                          alignSelf: "flex-end",
                          display: "contents",
                          p: 0,
                          m: 0,
                        }}
                      >
                        <Button
                          type="submit"
                          disabled={isStreaming}
                          onClick={(e) => {
                            e.preventDefault();
                            onSubmit();
                          }}
                          id="submit-button"
                          variant="contained"
                          sx={{ height: "30px" }}
                        >
                          <BsFillSendFill />
                        </Button>
                      </InputAdornment>
                    ),
                  }}
                />
              </form>
            </Box>
          </Box>
        </Box>
      </Box>
    </Container>
  );
}

export default Conversations;
